<template>
  <div>
    <div class="selector">
      <button v-on:click="select('handling')">Handling Metrics</button>
      <button v-on:click="select('processing')">Processing Metrics</button>
      <button v-on:click="select('other')">Other Metrics</button>
    </div>
    <div class="filters">
      <div>
        <label for="from">De :</label>
        <input type="date" id="from" v-model="from" />
      </div>
      <div>
        <label for="to">A :</label>
        <input type="date" id="to" v-model="to" />
      </div>
      <div>
        <label for="agents">Agents :</label>
        <select multiple v-model="selectedAgents">
          <option
            v-for="(agent, index) in agents"
            :key="index"
            :value="agent._id"
          >
            {{ agent.username }}
          </option>
        </select>
      </div>
      <button v-if="!filter" v-on:click="applyFilters(true)">
        Apply filters
      </button>
      <button v-if="filter" v-on:click="applyFilters(false)">
        Cancel filters
      </button>
    </div>
    <div class="handling" v-if="selected == 'handling'">
      <div class="metrics-container">
        <h2>Handling Metrics :</h2>
        <table>
          <tr>
            <th>Nombre de demandes identifiées</th>
            <td>{{ handling.identified }}</td>
          </tr>
          <tr>
            <th>Nombre de demandes prises en charge</th>
            <td>{{ handling.handled }}</td>
          </tr>
          <tr>
            <th>Taux de prise en charge</th>
            <td>
              <span v-if="handling.rate == null">- - -</span>{{ handling.rate }}
            </td>
          </tr>
          <tr>
            <th>Temps moyen de traitement</th>
            <td :class="{ red: checkGoal(handling.avgTime, 'avgTime') }">
              {{ new Date(handling.avgTime).getMinutes() }} min
            </td>
          </tr>
        </table>
      </div>
      <div class="typo-container">
        <h2>Typologies :</h2>
        <div
          class="typo"
          v-for="(typo, i) in chartData.typologyTab[0]"
          :key="i"
        >
          <p class="typo-class" v-if="typo == null">Sans catégories</p>
          <p class="typo-class" v-else>{{ typology[0][typo] }}</p>
          <table>
            <tr>
              <th>Subclass</th>
              <th>Final classification</th>
              <th>Occurrences</th>
            </tr>
            <tr v-for="(sub, j) in chartData.typologyTab[1][i]" :key="j">
              <td>
                <span class="none" v-if="sub[0] == null">- - -</span>
                <span v-if="isNumeric(sub[0])">{{
                  typology[1][typo][sub[0]]
                }}</span>
                <span v-if="!isNumeric(sub[0]) && sub[0] != null">
                  Other : {{ sub[0] }}</span
                >
              </td>
              <td>
                <span class="none" v-if="sub[1] == null">- - -</span>
                <span v-else>{{ typology[2][typo][sub[1]] }}</span>
              </td>
              <td>{{ sub[2] }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="processing" v-if="selected == 'processing'">
      <div class="metrics-container">
        <h2>Processing Metrics :</h2>
        <table>
          <tr>
            <th>Nombre de demandes traitées</th>
            <td>{{ processing.done }}</td>
          </tr>
          <tr>
            <th>Taux de traitement</th>
            <td>{{ formatPercent(processing.rate) }}</td>
          </tr>
          <tr>
            <th>Temps moyen de traitement</th>
            <td :class="{ red: checkGoal(processing.avgTime, 'avgTime') }">
              {{ new Date(processing.avgTime).getMinutes() }} min
            </td>
          </tr>
          <tr>
            <th>Deflection rate</th>
            <td>{{ formatPercent(processing.bddUse) }}</td>
          </tr>
          <tr>
            <th>Taux de traitement « once and done »</th>
            <td>{{ processing.onceAndDone }}</td>
          </tr>
        </table>
      </div>
      <div class="typo-container">
        <h2>Typologies :</h2>
        <div
          class="typo"
          v-for="(typo, i) in chartData.typologyTab[0]"
          :key="i"
        >
          <p class="typo-class" v-if="typo == null">Sans catégories</p>
          <p class="typo-class" v-else>{{ typology[0][typo] }}</p>
          <table>
            <tr>
              <th>Subclass</th>
              <th>Final classification</th>
              <th>Occurrences</th>
              <th>Nbr interaction moy. par typo</th>
            </tr>
            <tr v-for="(sub, j) in chartData.typologyTab[1][i]" :key="j">
              <td>
                <span class="none" v-if="sub[0] == null">- - -</span>
                <span v-if="isNumeric(sub[0])">{{
                  typology[1][typo][sub[0]]
                }}</span>
                <span v-if="!isNumeric(sub[0]) && sub[0] != null">
                  Other : {{ sub[0] }}</span
                >
              </td>
              <td>
                <span class="none" v-if="sub[1] == null">- - -</span>
                <span v-else>{{ typology[2][typo][sub[1]] }}</span>
              </td>
              <td>{{ sub[2] }}</td>
              <td>{{ formatAverage(sub[2], sub[3]) }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="other" v-if="selected == 'other'">
      <div class="metrics-container">
        <h2>Processing Metrics :</h2>
        <table>
          <tr>
            <th>Taux de gratitude</th>
            <td>- - -</td>
          </tr>
          <tr>
            <th>Taux de satisfaction (après enquète)</th>
            <td>- - -</td>
          </tr>
          <tr>
            <th>Taux d'appels suite a une demande</th>
            <td>- - -</td>
          </tr>
        </table>
      </div>
      <div class="typo-container">
        <h2>Typologies :</h2>
        <div
          class="typo"
          v-for="(typo, i) in chartData.typologyTab[0]"
          :key="i"
        >
          <p class="typo-class" v-if="typo == null">Sans catégories</p>
          <p class="typo-class" v-else>{{ typology[0][typo] }}</p>
          <table>
            <tr>
              <th>Subclass</th>
              <th>Final classification</th>
              <th>Occurrences</th>
              <th>Durée moyenne des appels</th>
              <th>Taux sur total</th>
            </tr>
            <tr v-for="(sub, j) in chartData.typologyTab[1][i]" :key="j">
              <td>
                <span class="none" v-if="sub[0] == null">- - -</span>
                <span v-if="isNumeric(sub[0])">{{
                  typology[1][typo][sub[0]]
                }}</span>
                <span v-if="!isNumeric(sub[0]) && sub[0] != null">
                  Other : {{ sub[0] }}</span
                >
              </td>
              <td>
                <span class="none" v-if="sub[1] == null">- - -</span>
                <span v-else>{{ typology[2][typo][sub[1]] }}</span>
              </td>
              <td>{{ sub[2] }}</td>
              <td>- - -</td>
              <td>{{ calcTypoTotal(sub[2]) }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "MetricsDisplay",
  data() {
    return {
      selected: "handling",
      handling: {
        typology: [],
      },
      processing: {
        typology: [],
      },
      other: {
        typology: [],
      },
      chartData: {
        typologyTab: [[], []],
      },
      agents: [],
      selectedAgents: [],
      from: 0,
      to: 0,
      filter: false,
      typology: [
        [
          "Lancement service",
          "Demande renseignements",
          "Demande de service",
          "Demande de réservation",
          "Demande de confirmation",
          "Interrogation services TA",
          "Conversation",
          "Situation d'urgence",
          "Relance",
          "Réclamation/retour Agences",
        ],
        [
          ["Prise de contact et ouverture du service"],
          [
            "Transport",
            "Loisir",
            "Restauration",
            "Activités",
            "Pratique",
            "Santé",
            "Culture",
            "Hebergement",
            "Boutique",
            "COVID",
            "Demandes insolites (tatouage etc...)",
            "Climat",
            "Us et coutumes",
            "Demandes illicites (prostitution, drogue etc...)",
          ],
          ["Idées pour demande en mariage ", "Pour cadeaux surprise"],
          [
            "Restaurant",
            "Taxi voiture",
            "Taxi moto",
            "Uber",
            "Limousine ou voiture de luxe",
            "Navette",
            "Avion",
            "Train",
            "Hélocoptère",
            "Vélo",
            "Pass-Transport",
            "Spectacle (concert, cinéma, évènement sportif...)",
            "Bateau",
            "Montgolfière",
            "Demandes insolites",
            "Scooter",
            "Guide local",
            "Activités",
            "Hebergement (hotel, maison d'hôte, auberge...)",
            "Loisirs",
          ],
          [
            "Restaurant",
            "Taxi voiture",
            "Taxi moto",
            "Uber",
            "Limousine ou voiture de luxe",
            "Navette",
            "Avion",
            "Train",
            "Hélocoptère",
            "Vélo",
            "Pass-Transport",
            "Spectacle (concert, cinéma, évènement sportif...)",
            "Bateau",
            "Montgolfière",
            "Demandes insolites",
            "Scooter",
            "Guide local",
            "Activités",
            "Hebergement (hotel, maison d'hôte, auberge...)",
            "Loisirs",
          ],
          [
            "Qu'inclus le service",
            "Si on peut faire les réservations pour eux",
            "Si nous réservons comment payer et est ce qu'il y a un coût supl.",
            "Si c'est un robot ou des humain derrière",
          ],
          [
            "Partage de photos",
            "Partage d'expérience ",
            "Proposition du client d'un resto ou activtié découverte par hasard",
          ],
          [
            "Perte de papiers d'identité ou passeport",
            "Perte de clés",
            "Perte de moyens de paiement",
            "Attentat",
            "Problème climatique",
            "Panne de voiture",
            "Perdu",
            "Oubli d'un objet ou autre (dans l'hôtel, avion...)",
            "Retard pour un resto ou un hôtel",
            "Raté l'avion",
            "Crise sanitaire",
            "Est ce que c'est bien inclus dans leur package",
            "Problème de chambre ou d'hôtel",
            "Agression",
            "Problème de santé",
            "Décès",
            "Rapatriement",
          ],
          ["Prise de nouvelles", "Choix non determiné", "Suggestion, conseil"],
          [
            "Problème sur une réservation",
            "Retour sur une insatisfaction client ",
            "Accessibilité à la conciergerie",
          ],
        ],
        [
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [],
          [],
          [
            "Prise en compte en moins de 5 minutes",
            "Prise en compte en moins de 10 minutes",
            "Prise en compte en moins de 15 minutes",
            "Prise en compte en mois de 20 minutes",
            "Prise en compte supérieure à 20 minutes",
          ],
          [
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
        ],
      ],
    };
  },
  async created() {
    await this.getMetrics();
  },
  methods: {
    isNumeric: function (str) {
      if (typeof str != "string") return false;
      return !isNaN(str) && !isNaN(parseFloat(str));
    },
    checkGoal: function (n, c) {
      switch (c) {
        case "avgTime":
          if (n > 20) {
            return true;
          } else {
            return false;
          }
        default:
          return false;
      }
    },
    applyFilters: async function (value) {
      if (value) {
        this.filter = true;
        await this.getMetrics();
      } else {
        this.to = 0;
        this.from = 0;
        this.selectedAgents = [];
        this.filter = false;
        await this.getMetrics();
      }
    },
    openAgentModal: function () {
      this.$modal.show("agents-modal");
    },
    formatPercent: function (n) {
      let percent = n * 100;
      let res;
      res = percent.toFixed(2);
      return res + " %";
    },
    formatAverage: function (o, a) {
      if (o !== undefined && a !== undefined) {
        let sum = a.reduce((a, b) => a + b, 0);
        let temp = sum / o;
        let avg = temp.toFixed(2);
        return avg;
      } else {
        return 0;
      }
    },
    calcTypoTotal: function (n) {
      let temp = (n / this.other.identified) * 100;
      return temp.toFixed(2) + " %";
    },
    getMetrics: async function () {
      this.agents = await HandleApi.getAgent("?limit=9999");
      let query = "?filter=true";
      if (this.to !== 0) {
        query += "&to=" + this.to;
      }
      if (this.from !== 0) {
        query += "&from=" + this.from;
      }
      if (this.selectedAgents.length > 0) {
        query += "&agent=" + this.selectedAgents;
      }
      switch (this.selected) {
        case "handling":
          this.handling = await HandleApi.getHandlingMetrics(query);
          await this.generateTypologyTab();
          break;
        case "processing":
          this.processing = await HandleApi.getProcessingMetrics(query);
          await this.generateTypologyTab();
          break;
        case "other":
          this.other = await HandleApi.getOtherMetrics(query);
          await this.generateTypologyTab();
          break;
      }
    },
    select: async function (val) {
      this.selected = val;
      await this.getMetrics();
    },
    generateTypologyTab: async function () {
      let data;
      let temp1 = [];
      let temp2 = [];
      switch (this.selected) {
        case "handling":
          data = this.handling.typology;
          for (let i = 0; i < data.typology.length; i++) {
            if (temp1.includes(data.typology[i][0])) {
              let n = temp1.indexOf(data.typology[i][0]);
              temp2[n].push([
                data.typology[i][1],
                data.typology[i][2],
                data.occurrence[i],
              ]);
            } else {
              temp1.push(data.typology[i][0]);
              temp2.push([
                [data.typology[i][1], data.typology[i][2], data.occurrence[i]],
              ]);
            }
          }
          for (let i = 0; i < temp2.length; i++) {
            let sortedArray = temp2[i].sort(function (a, b) {
              if (a[0] == b[0]) {
                return a[1] - b[1];
              }
              return b[0] - a[0];
            });
            temp2[i] = sortedArray;
          }
          this.chartData.typologyTab = [temp1, temp2];
          break;
        case "processing":
          data = this.processing.typology;
          for (let i = 0; i < data.typology.length; i++) {
            if (temp1.includes(data.typology[i][0])) {
              let n = temp1.indexOf(data.typology[i][0]);
              temp2[n].push([
                data.typology[i][1],
                data.typology[i][2],
                data.occurrence[i],
                data.interaction[i],
              ]);
            } else {
              temp1.push(data.typology[i][0]);
              temp2.push([
                [
                  data.typology[i][1],
                  data.typology[i][2],
                  data.occurrence[i],
                  data.interaction[i],
                ],
              ]);
            }
          }
          for (let i = 0; i < temp2.length; i++) {
            let sortedArray = temp2[i].sort(function (a, b) {
              if (a[0] == b[0]) {
                return a[1] - b[1];
              }
              return b[0] - a[0];
            });
            temp2[i] = sortedArray;
          }
          this.chartData.typologyTab = [temp1, temp2];
          break;
        case "other":
          data = this.handling.typology;
          for (let i = 0; i < data.typology.length; i++) {
            if (temp1.includes(data.typology[i][0])) {
              let n = temp1.indexOf(data.typology[i][0]);
              temp2[n].push([
                data.typology[i][1],
                data.typology[i][2],
                data.occurrence[i],
              ]);
            } else {
              temp1.push(data.typology[i][0]);
              temp2.push([
                [data.typology[i][1], data.typology[i][2], data.occurrence[i]],
              ]);
            }
          }
          for (let i = 0; i < temp2.length; i++) {
            let sortedArray = temp2[i].sort(function (a, b) {
              if (a[0] == b[0]) {
                return a[1] - b[1];
              }
              return b[0] - a[0];
            });
            temp2[i] = sortedArray;
          }
          this.chartData.typologyTab = [temp1, temp2];
          break;
      }
    },
  },
};
</script>

<style scoped>
.selector,
.filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin: 0 auto;
}
.selector button {
  margin: 0 25px;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 40px;
  padding: 5px 5px;
  background-color: rgb(0, 144, 159);
  border: none;
  cursor: pointer;
}
.selector button:hover {
  background-color: rgb(3, 182, 202);
}
.filters button {
  margin: 0 25px;
  height: 24px;
}
.filters {
  margin-top: 10px;
}
h2 {
  margin-left: 10%;
}
.metrics-container > table {
  width: 60%;
  margin-left: 10%;
  text-align: center;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
.typo-class {
  font-size: 120%;
  font-weight: bold;
}
.typo {
  width: 80%;
  margin: 0 auto;
}
.typo > table {
  width: 100%;
}
.typo > table > tr {
  text-align: center;
}
.typo > table > tr > :nth-child(1) {
  width: 20%;
}
.typo > table > tr > :nth-child(3),
.typo > table > tr > :nth-child(4),
.typo > table > tr > :nth-child(5) {
  width: 10%;
}
.red {
  color: red;
  font-weight: bold;
}
</style>
